import React from 'react'
import Layout from '../components/layout/layout'
import ThankYou from '../components/thankYou/thankYou'

export default function PortalPage() {
  return (
    <Layout>
      <ThankYou />
    </Layout>
  );
}