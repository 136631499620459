import React, { useEffect } from 'react'
import styles from './thankYou.module.scss'
import PropTypes from 'prop-types'
import { getStorageItem } from '../../utilities/getStorageItem'
import Header from '../header/header'
import Footer from '../footer/footer'

export const Icon = ({ img, text }) => (
  <div className={styles.icon}>
    <img src={img} alt =""/>
    <p>{text}</p>
  </div>
)

Icon.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
}

export default function ThankYou() {
  const path = getStorageItem('mcn-form-path')

  const icons = [
    <Icon {...{
      img: '/confirmation.svg',
      text: 'Email confirmation within 24 hours'
    }} key={1} />,
  ]

  if (path === 'renew-tag') {
    icons.push(<Icon {...{
      img: '/sticker.svg',
      text: 'Plate sticker within 8-12 days'
    }} key={2} /> )
  }

  if (path === 'renew-boat') {
    icons.push(<Icon {...{
      img: '/sticker.svg',
      text: 'Boat decal within 8-10 days'
    }} key={2} /> )
  }

  if (path === 'lien-title-request') {
    icons.push(<Icon {...{
      img: '/document.svg',
      text: 'Title within 30 days'
    }} key={2} /> )
  }
  
  const paymentMessage = (type) => `We have received your ${type} information and payment.`

  const message = {
    'renew-tag': paymentMessage('tag renewal'),
    'decal-replacement': paymentMessage('decal replacement'),
    'disability-placard': paymentMessage('handicap placard'),
    'duplicate-title-request': paymentMessage('duplicate title'),
    'lien-title-request': paymentMessage('lien holder title'),
    'registration-request': paymentMessage('registration request'),
    'repo-instructions': paymentMessage('repossession'),
    'change-address': 'We have received your change of address request.',
    'personalized-tag': paymentMessage('personalized tag'),
    'vehicle-info': 'We have received your vehicle information request.',
  }

  const title = {
    'renew-tag': 'Tag Renewal',
    'decal-replacement': 'Decal Replacement',
    'disability-placard': 'Handicap Placard',
    'duplicate-title-request': 'Duplicate Title Request',
    'lien-title-request': 'Lien Holder Title Request',
    'registration-request': 'Registration Request',
    'repo-instructions': 'Repossession Instructions',
    'change-address': 'Change of Address',
    'personalized-tag': 'Personalized Tag',
    'vehicle-info': 'Vehicle Information',
  }

  useEffect(() => {
    window.sessionStorage.clear()
  }, [])

  return (
    <section className={styles.section}>
      <Header title={title[path]}/>
      <div className={styles.container}>
        <img className={styles.plate} src="/plate.png" alt="" />
        <h1>Thank you!</h1>
        <h2>{message[path]}</h2>

        <div className={styles.expect}>
          <h2>Expect to receive:</h2>

          <div className={styles.row}>
            <div className={styles.iconContainer}>
              {icons}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}